import { HeaderProps } from "containers/Header/Header";
import { useEntityConfigurationValueByKey } from "hooks/useEntityConfiguration";

export const useHeaderConfiguration = (params?: HeaderProps & { allowHeaderTransparency?: boolean }) => {
    const { allowHeaderTransparency, isTransparent, ...restConfigs } = params ?? {};
    const isSticky = useEntityConfigurationValueByKey<boolean>("SETTING.HEADER_STICKY") ?? false;
    const isTransparentConfig = useEntityConfigurationValueByKey<boolean>("SETTING.HEADER_TRANSPARENT");
    const effectiveTransparency = allowHeaderTransparency ? isTransparent ?? isTransparentConfig ?? false : false;

    return {
        isSticky,
        isTransparent: effectiveTransparency,
        ...restConfigs,
    };
};
