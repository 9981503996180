import { ElementOf } from "ts-essentials";

export const ThemeNamedColourValues = [
    "primary",
    "primaryLight",
    "primaryDark",
    "secondary",
    "secondaryLight",
    "secondaryDark",
] as const;
export type ThemeNamedColour = ElementOf<typeof ThemeNamedColourValues>;
export const TextNamedColourValues = ["text", "textSecondary", "black", "white"] as const;
export const ThemeDefaultNamedColourValues = ["light", "dark"] as const;
export type ThemeDefaultNamedColour = ElementOf<typeof ThemeDefaultNamedColourValues>;
export type TextNamedColour = ElementOf<typeof TextNamedColourValues>;

export type NamedColour = TextNamedColour | ThemeNamedColour | ThemeDefaultNamedColour;

export function isTextNamedColour(value: unknown): value is TextNamedColour {
    return TextNamedColourValues.includes(value as TextNamedColour);
}

export function isThemeNamedColour(value: unknown): value is ThemeNamedColour {
    return ThemeNamedColourValues.includes(value as ThemeNamedColour);
}

export function isThemeDefaultNamedColour(value: unknown): value is ThemeDefaultNamedColour {
    return ThemeDefaultNamedColourValues.includes(value as ThemeDefaultNamedColour);
}

export function isNamedColour(value: unknown): value is NamedColour {
    return isTextNamedColour(value) || isThemeNamedColour(value) || isThemeDefaultNamedColour(value);
}
