import { ComponentType, HTMLAttributes, createContext, useContext, useMemo } from "react";

import type { Hbml, HbmlNode, HbmlTextNode } from "@holibob-packages/hbml";
import { isTextNode } from "@holibob-packages/hbml";

import { HbmlTextRawNode } from "./HbmlComponents/HbmlTextNode";
import { HbmlUnknownSilentNode } from "./HbmlComponents/HbmlUnknownSilentNode";

export type HbmlTextNodeComponentType = ComponentType<{ content: HbmlTextNode }>;

export type HbmlElementComponentProps = {
    content: any;
} & Omit<HTMLAttributes<HTMLElement>, "content">;
export type HbmlElementNodeComponentType = ComponentType<HbmlElementComponentProps>;

export type HbmlRendererComponents = Record<
    string,
    HbmlTextNodeComponentType | HbmlElementNodeComponentType | undefined
>;

export type HbmlRendererProps = {
    content: Hbml;
    components: HbmlRendererComponents;
    textComponent?: HbmlTextNodeComponentType;
};

const context = createContext<{
    components: HbmlRendererComponents;
    textComponent: HbmlTextNodeComponentType;
}>({
    textComponent: HbmlTextRawNode,
    components: {},
});

export function HbmlRenderer({ content, components, textComponent }: HbmlRendererProps) {
    const Provider = context.Provider;
    const contextValue = useMemo(() => {
        return {
            components,
            textComponent: textComponent ?? HbmlTextRawNode,
        };
    }, [components, textComponent]);
    return (
        <Provider value={contextValue}>
            <HbmlRendererNodes content={content} />
        </Provider>
    );
}

type HbmlRendererNodesProps = {
    content: HbmlNode[];
};

export function HbmlRendererNodes({ content }: HbmlRendererNodesProps) {
    return (
        <>
            {content.map((node, i) => (
                <HbmlRendererNode node={node} key={i} />
            ))}
        </>
    );
}

export function HbmlRendererNode({ node }: { node: HbmlNode }) {
    const { components, textComponent: TextComponent } = useContext(context);

    if (isTextNode(node)) {
        return <TextComponent content={node} />;
    }

    const Component = (
        "type" in node && typeof node.type === "string"
            ? components[node.type] ?? HbmlUnknownSilentNode
            : HbmlUnknownSilentNode
    ) as HbmlElementNodeComponentType;

    return <Component content={node as HbmlElementComponentProps["content"]} />;
}
