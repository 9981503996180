import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { styled } from "@mui/material/styles";
import { HTMLAttributes } from "react";

import { useProductTranslation } from "../hooks/useProductTranslation";

export type ProductReviewsInfoProps = {
    rating: number;
    reviewsNumber: number;
} & HTMLAttributes<HTMLDivElement>;

const Wrapper = styled("div")(({ theme }) => ({
    display: "inline-flex",
    gap: theme.spacing(1),
}));

const Caption = styled("span")(({ theme }) => ({
    fontSize: 14,
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    alignSelf: "center",
}));

export function ProductReviewsInfo({ rating, reviewsNumber, ...props }: ProductReviewsInfoProps) {
    const [t] = useProductTranslation();
    return (
        <Wrapper {...props}>
            <Stars rating={rating} />
            <Caption>{t("reviews.label", { count: reviewsNumber })}</Caption>
        </Wrapper>
    );
}

const StarsWrapper = styled("div")({
    display: "inline-flex",
    gap: 1,
});

export function Stars({ rating }: { rating: number }) {
    const stars = [];
    let remain = rating;
    for (let i = 0; i < 5; i++) {
        let star = <StarIcon key={i} htmlColor="#FF9800" />;
        if (remain <= 0) {
            star = <StarOutlineIcon key={i} htmlColor="#FF9800" />;
        } else if (remain < 1) {
            star = <StarHalfIcon key={i} htmlColor="#FF9800" />;
        }
        stars.push(star);
        remain -= 1;
    }
    return <StarsWrapper>{stars}</StarsWrapper>;
}
