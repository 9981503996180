import { styled } from "@mui/material/styles";
import React, { ForwardedRef, forwardRef, HTMLAttributes, ReactNode } from "react";

import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

export const HbmlTableNode = hbmlNodeFactory<
    typeof HbmlElements.TABLE,
    Record<string, unknown>,
    Omit<HTMLAttributes<HTMLTableElement>, "content"> & {
        beforeBodyContent?: ReactNode;
        tbodyAttributes?: HTMLAttributes<HTMLTableSectionElement>;
    }
>({
    type: HbmlElements.TABLE,
    Component: forwardRef(function HbmlTableNodeInternalComponent(
        { node: _node, beforeBodyContent, tbodyAttributes, children, ...props },
        ref: ForwardedRef<HTMLTableElement>
    ) {
        return (
            <TableStyled ref={ref} {...props}>
                {beforeBodyContent}
                <tbody {...tbodyAttributes}>{children}</tbody>
            </TableStyled>
        );
    }),
});

const TableStyled = styled("table")(({ theme }) => ({
    border: `1px solid`,
    borderColor: theme.palette.border.subtle,
    borderCollapse: "collapse",
}));
