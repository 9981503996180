import { styled } from "@mui/material/styles";
import React, { ForwardedRef, forwardRef, HTMLAttributes } from "react";

import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

export const HbmlTableCellNode = hbmlNodeFactory<
    typeof HbmlElements.TABLE_CELL,
    Record<string, unknown>,
    Omit<HTMLAttributes<HTMLTableCellElement>, "content">
>({
    type: HbmlElements.TABLE_CELL,
    Component: forwardRef(function HbmlTableCellNodeInternalComponent(
        { node: _node, ...props },
        ref: ForwardedRef<HTMLTableCellElement>
    ) {
        return <CellStyled ref={ref} {...props} />;
    }),
});

const CellStyled = styled("td")(({ theme }) => ({
    border: "1px solid",
    borderColor: theme.palette.border.subtle,
    padding: theme.spacing(0.5, 1),
    "& p": {
        margin: 0,
    },
}));
