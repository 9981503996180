import { styled } from "@mui/material/styles";
import { HTMLAttributes } from "react";

const ItemWrapper = styled("div")({
    scrollSnapAlign: "center",
    flexShrink: 0,
});

export function ScrollableContainerItem(props: HTMLAttributes<HTMLDivElement>) {
    return <ItemWrapper data-ref="scrollable-item" {...props} />;
}
