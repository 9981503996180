import omit from "lodash/omit";
import { HTMLAttributes, ImgHTMLAttributes } from "react";

import { AssetUrl, assetUrlToVaultUrl, ResizePosition, VaultUrl } from "@holibob-packages/vault";

import { computeSrcSet } from "../utils";

export type ResponsiveImageDimensionDefinition = { height: number } & (
    | ResponsiveImageDimensionDefinitionSizes
    | ResponsiveImageDimensionDefinitionWidth
);
export type ResponsiveImageSrc = string | AssetUrl | VaultUrl | URL;
export type ResponsiveImageResolution = 1 | 1.5 | 2;
export type ResponsiveImageDimensionDefinitionSizes = { srcSetSizes: number[]; sizes: string };
export type ResponsiveImageDimensionDefinitionWidth = { width: number };
export type ResponsiveImageSrcFactoryArgs = {
    vaultUrl: VaultUrl;
    type: "strict-dimensions" | "dynamic-width-strict-height";
    width: number;
    height: number;
    resolution: ResponsiveImageResolution;
};

export type ResponsiveImageSrcFactory = (args: ResponsiveImageSrcFactoryArgs) => VaultUrl;
export type ResponsiveImageProps = {
    /**
     * Image SRC
     *
     * When regular string gets provided it is assumed to be VaultUrl string format so it is converted to VaultUrl first then operates on it.
     * AssetUrl is converted to VaultUrl first then operates on it.
     * Whenever URL instance gets provided we assume this is SVG or another image that cannot be transformed in fly.
     */
    src: ResponsiveImageSrc;
    alt: string;
    fitStrategy?: "cover" | "contain";
    extendedBackground?: string;
    /**
     * Default resize position applied to transformed images ONLY if there is no resize position already defined in VaultUrl or AssetUrl
     */
    defaultResizePosition?: ResizePosition;
    ImageComponent?: React.ComponentType<ImgHTMLAttributes<HTMLImageElement>> | "img";
    srcFactory?: ResponsiveImageSrcFactory;
} & HTMLAttributes<HTMLPictureElement> &
    Pick<ImgHTMLAttributes<HTMLImageElement>, "loading"> &
    ResponsiveImageDimensionDefinition;

const RESOLUTIONS = [2, 1.5] as const;

export function ResponsiveImage({
    src,
    height,
    loading = "lazy",
    ImageComponent = "img",
    defaultResizePosition,
    alt,
    fitStrategy = "cover",
    extendedBackground = "#FFFFFF00",
    srcFactory: srcFactoryProp = defaultSrcFactory,
    ...props
}: ResponsiveImageProps) {
    const vaultUrl = srcToVaultUrl(src);
    if (!vaultUrl) {
        return null;
    }

    if (vaultUrl instanceof URL) {
        return (
            <ImageComponent
                src={vaultUrl.toString()}
                {...omit(props, ["sizes", "srcSetSizes"])}
                height={height}
                width={"width" in props ? props.width : undefined}
            />
        );
    }

    const srcFactory: ResponsiveImageSrcFactory = (args) => {
        const vaultUrl = srcFactoryProp(args);
        return vaultUrl.modifyEdits({
            resize: {
                fit: fitStrategy,
                position: vaultUrl.edits?.resize?.position ?? defaultResizePosition,
                background: extendedBackground,
                ...vaultUrl.edits?.resize,
            },
        });
    };

    const hasWidth = "width" in props;
    const hasSrcSet = "srcSetSizes" in props;
    const dimensions = hasWidth ? { width: props.width } : { srcSetSizes: props.srcSetSizes, sizes: props.sizes };

    const defaultImageSrc = hasWidth
        ? srcFactory({ type: "strict-dimensions", width: props.width, height, resolution: 1, vaultUrl }).toString()
        : srcFactory({
              type: "dynamic-width-strict-height",
              vaultUrl,
              resolution: 1,
              width: Math.max(...props.srcSetSizes),
              height,
          }).toString();
    return (
        <picture {...omit(props, ["width", "sizes", "srcSetSizes"])}>
            {RESOLUTIONS.map((resolution) => {
                return (
                    <Source
                        vaultUrl={vaultUrl}
                        resolution={resolution}
                        srcFactory={srcFactory}
                        height={height}
                        {...dimensions}
                        key={resolution}
                    />
                );
            })}
            {hasSrcSet && (
                <Source vaultUrl={vaultUrl} resolution={1} height={height} {...dimensions} srcFactory={srcFactory} />
            )}
            <ImageComponent
                src={defaultImageSrc}
                loading={loading}
                alt={alt}
                height={height}
                width={"width" in props ? props.width : undefined}
                decoding="async"
            />
        </picture>
    );
}

type SourceProps = {
    resolution: ResponsiveImageResolution;
    srcFactory: ResponsiveImageSrcFactory;
    sizes?: string;
    vaultUrl: VaultUrl;
} & ResponsiveImageDimensionDefinition;

function Source({ vaultUrl, srcFactory, sizes, height, resolution, ...props }: SourceProps) {
    const media = resolution > 1 ? `(min-resolution: ${resolution}dppx)` : undefined;

    const srcSet =
        "width" in props
            ? srcFactory({ vaultUrl, type: "strict-dimensions", width: props.width, height, resolution }).toString()
            : computeSrcSet(props.srcSetSizes, {
                  srcFactory: ({ width }) => {
                      return srcFactory({
                          vaultUrl,
                          type: "dynamic-width-strict-height",
                          width,
                          height,
                          resolution,
                      }).toString();
                  },
              });

    return <source media={media} sizes={sizes} srcSet={srcSet} />;
}

export const defaultSrcFactory: ResponsiveImageSrcFactory = ({ vaultUrl, type, width, height, resolution }) => {
    const finalWidth = type === "strict-dimensions" ? width * resolution : width;
    const finalHeight = height * resolution;
    return vaultUrl.modifyEdits({
        resize: {
            ...vaultUrl.edits?.resize,
            width: finalWidth,
            height: finalHeight,
        },
    });
};

export function srcToVaultUrl(src: ResponsiveImageProps["src"]): VaultUrl | URL | undefined {
    if (src instanceof AssetUrl) {
        return assetUrlToVaultUrl(src);
    }
    if (src instanceof VaultUrl || src instanceof URL) {
        return src;
    }

    if (src.startsWith("asset://")) {
        const url = AssetUrl.fromString(src).mapRight(assetUrlToVaultUrl);
        return url.isRight() ? url.value : undefined;
    }
    const url = VaultUrl.fromString(src);
    return url.isRight() ? url.value : undefined;
}
