import { Theme, useTheme } from "@mui/material/styles";
import { useMemo } from "react";

export function createNamedThemeColors(theme: Theme): Record<string, string> {
    return {
        text: theme.palette.text.primary,
        textSecondary: theme.palette.text.disabled,
        black: theme.palette.common.black,
        white: theme.palette.common.white,
    };
}

export function useNamedThemeColors(): Record<string, string> {
    const theme = useTheme();

    return useMemo(() => createNamedThemeColors(theme), [theme]);
}
