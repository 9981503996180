import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { TOOLBAR_HEIGHT } from "../types/HeaderBar";

export const useNavbarHeight = () => {
    const theme = useTheme();
    const upSm = useMediaQuery(theme.breakpoints.up("sm"));
    const upMd = useMediaQuery(theme.breakpoints.up("md"));

    return upSm ? TOOLBAR_HEIGHT.md : upMd ? TOOLBAR_HEIGHT.lg : TOOLBAR_HEIGHT.sm;
};
