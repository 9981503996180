import { styled } from "@mui/material/styles";

export const HoverableCard = styled("div")(({ theme }) => ({
    position: "relative",
    boxShadow: "unset",
    overflow: "hidden",
    borderRadius: theme.roundness,
    "&:after": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        pointerEvents: "none",
        opacity: 0.8,
        borderRadius: theme.roundness,
        zIndex: 10,
        transition: theme.transitions.create("box-shadow", {
            duration: theme.transitions.duration.short,
        }),
    },
    "&:hover": {
        "&:after": {
            boxShadow: `inset 0 0 0 2px ${theme.palette.primary.main}`,
        },
    },
}));

export const HoverableCardV2 = styled(HoverableCard)(({ theme }) => ({
    borderRadius: theme.roundness,
}));
