import { z } from "zod";

const EmailSchema = z.string().trim().email();
const MAILTO_PREFIX = "mailto:";

const FORMAT_ERROR_MESSAGE = 'Url must start with "http://", "https://", "/", "#", "mailto:" or "tel:"';
export const UrlOrUrlPartsAttributeSchema = z.union([
    z.string().trim().startsWith("http://", FORMAT_ERROR_MESSAGE).url(),
    z.string().trim().startsWith("https://", FORMAT_ERROR_MESSAGE).url(),
    z.string().trim().startsWith("/", FORMAT_ERROR_MESSAGE),
    z.string().trim().startsWith("#", FORMAT_ERROR_MESSAGE),
    z
        .string()
        .trim()
        .startsWith("mailto:", FORMAT_ERROR_MESSAGE)
        .refine((value) => {
            const questionMarkPosition = value.indexOf("?");
            const email = value.substring(
                MAILTO_PREFIX.length,
                questionMarkPosition === -1 ? undefined : questionMarkPosition
            );
            return EmailSchema.safeParse(email).success;
        }, "Invalid email in `mailto:`"),
    z.string().trim().startsWith("tel:", FORMAT_ERROR_MESSAGE),
    z
        .string()
        .trim()
        .refine((value) => value.length === 0, "If not an URL then must be an empty string"),
]);
