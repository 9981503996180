import { styled, useTheme } from "@mui/material/styles";
import { ForwardedRef, forwardRef, HTMLAttributes, ReactNode } from "react";

import type { Shade } from "@holibob-packages/colour";
import type { HbmlTextNode } from "@holibob-packages/hbml";

import { useNamedThemeColors } from "../../hooks";
import { TypographyDocument, TypographyDocumentComponents } from "../TypographyDocument";

const Underline = styled(TypographyDocumentComponents.span)({
    textDecoration: "underline",
});

const Code = styled("code")(({ theme }) => ({
    padding: theme.spacing(0.5),
    borderRadius: 2,
    backgroundColor: theme.palette.grey[300],
}));

export const HbmlTextNodeComponent = forwardRef(function HbmlTextNodeComponentInternal(
    { node, children, ...props }: { node: HbmlTextNode } & HTMLAttributes<HTMLSpanElement>,
    ref: ForwardedRef<HTMLSpanElement>
) {
    const requiresSpan = !!ref || Object.keys(props).length > 0 || node.isSpecialFont;

    let content = requiresSpan ? (
        <TypographyDocument isSpecialFont={node.isSpecialFont} variant="span" {...props} ref={ref}>
            {children}
        </TypographyDocument>
    ) : (
        <>{children}</>
    );

    if (node.bold) {
        content = (
            <TypographyDocument variant="strong" isSpecialFont={node.isSpecialFont}>
                {content}
            </TypographyDocument>
        );
    }
    if (node.italic) {
        content = (
            <TypographyDocument variant="em" isSpecialFont={node.isSpecialFont}>
                {content}
            </TypographyDocument>
        );
    }
    if (node.underline) {
        content = <Underline>{content}</Underline>;
    }
    if (node.code) {
        content = <Code>{content}</Code>;
    }
    if (node.color) {
        content = (
            <ColoredText color={node.color} shade={node.shade}>
                {content}
            </ColoredText>
        );
    }
    return content;
});

type ColoredTextProps = {
    children: ReactNode;
    color: string | number;
    shade?: Shade;
};

function ColoredText({ color, shade, children }: ColoredTextProps) {
    const finalColor = useColor(color, shade);

    return <span style={{ color: finalColor }}>{children}</span>;
}

function useColor(color: string | number, shade?: Shade) {
    const allColors = useNamedThemeColors();
    const spotColours = useTheme().palette.spotColours;

    const normalizedColor = normalizeColor(color, shade);

    if ("shade" in normalizedColor) {
        return spotColours.get(normalizedColor.color)?.[normalizedColor.shade];
    }

    return allColors[normalizedColor.color];
}

/**
 * Normalizes color name to use
 * @param color
 */
function normalizeColor(
    color: string | number,
    shade: Shade | undefined
): { color: string } | { color: number; shade: Shade } {
    if (typeof color === "number") {
        return { color, shade: shade ?? 100 };
    }
    if (color === "primary") {
        return { color: -2, shade: 100 };
    } else if (color === "primaryLight") {
        return { color: -2, shade: 70 };
    } else if (color === "primaryDark") {
        return { color: -2, shade: 400 };
    } else if (color === "secondary") {
        return { color: -1, shade: 100 };
    } else if (color === "secondaryLight") {
        return { color: -1, shade: 70 };
    } else if (color === "secondaryDark") {
        return { color: -1, shade: 400 };
    }

    return { color };
}

export function HbmlTextRawNode({ content }: { content: HbmlTextNode }) {
    return <HbmlTextNodeComponent node={content}>{content.text}</HbmlTextNodeComponent>;
}
