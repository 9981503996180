import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useInView } from "react-intersection-observer";

const Wrapper = styled("div")(({ theme }) => ({
    transition: theme.transitions.create(["opacity", "transform"], {
        duration: 500,
        easing: theme.transitions.easing.easeOut,
    }),
    transform: "translateY(200px)",
    opacity: 0,
    maxWidth: "100%",
    '&[data-has-been-viewed="true"]': {
        transform: "none",
        opacity: 1,
    },
}));

export type FadeUpAnimationWrapperProps = {
    threshold?: number;
    isEnabled?: boolean;
    children: React.ReactNode;
};

export const FadeUpAnimationWrapper = ({
    threshold = 0.1,
    isEnabled = true,
    ...props
}: FadeUpAnimationWrapperProps) => {
    const { ref, inView } = useInView({
        threshold,
        rootMargin: "200px",
    });
    const [hasBeenViewed, setHasBeenViewed] = useState(!isEnabled);

    if (inView && !hasBeenViewed) {
        setHasBeenViewed(true);
    }
    return <Wrapper data-has-been-viewed={hasBeenViewed} ref={ref} {...props} />;
};
