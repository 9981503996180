import React, { HTMLAttributes, Ref } from "react";

import type { HbmlElementNode } from "@holibob-packages/hbml";

import { HbmlRendererNodes } from "../../HbmlRenderer";

function assignDisplayName<T extends { (...args: any[]): any; displayName?: string }>(func: T, name: string): T {
    func.displayName = name;
    return func;
}

/**
 * A helper that allows to create all elements necessary to handle
 */
export function hbmlNodeFactory<
    TType extends string,
    TNodeAttributes extends Record<string, unknown>,
    TReactAttributes extends Omit<HTMLAttributes<HTMLElement>, "content">,
>({
    Component: InternalComponent,
    NodeComponent,
    type: nodeType,
}: {
    /**
     * Node type. For example "hero", "paragraph"
     */
    type: TType;
    /**
     * Component responsible for rendering
     */
    Component: React.FunctionComponent<
        { node: HbmlElementNode & { type: TType } & TNodeAttributes } & TReactAttributes
    >;
    NodeComponent?: React.FunctionComponent<
        { content: HbmlElementNode & { type: TType } & TNodeAttributes } & TReactAttributes & {
                innerRef?: Ref<HTMLElement>;
            }
    >;
}) {
    return {
        nodeComponent:
            NodeComponent ??
            assignDisplayName(function NodeComponentInternal({
                content,
                innerRef,
                ...props
            }: {
                content: HbmlElementNode & TNodeAttributes;
            } & TReactAttributes & { innerRef?: Ref<HTMLElement> }) {
                return (
                    <InternalComponent
                        node={content as unknown as HbmlElementNode & { type: TType } & TNodeAttributes}
                        {...(props as unknown as TReactAttributes)}
                        ref={innerRef}
                    >
                        <HbmlRendererNodes content={content.children} />
                    </InternalComponent>
                );
            }, `HBML<${nodeType}>-node`),
        component: assignDisplayName(InternalComponent, `HBML<${nodeType}>-component`),
        nodeType,
        contentType: {} as unknown as HbmlElementNode & TNodeAttributes,
        attributes: {} as unknown as TNodeAttributes,
        type: {} as unknown as HbmlElementNode & TNodeAttributes,
    } as const;
}
