import { Dialog, DialogContent, DialogProps, DialogTitle } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useControlledState } from "@react-stately/utils";
import { HTMLAttributes, MouseEvent, Suspense, lazy } from "react";

import { Language } from "@holibob-packages/language";

import { usePriceFormatter } from "../hooks/usePriceFormatter";

const JSONTree = lazy(() => import("react-json-tree"));

export type PricingData = Record<string, unknown>;
export type PriceType = "PER_GROUP" | "PER_PERSON";

export type PricePropsBasic = {
    amount: number;
    language?: Language;
    currency?: string;
    size?: PriceSize;
    pricingData?: PricingData;
    parts?: Intl.NumberFormatPartTypes[];
};

type PricePropsDialog = {
    isPricingDataDialogOpen?: boolean;
    onPricingDataDialogOpenChange?: (isOpen: boolean) => void;
    defaultIsPricingDataDialogOpen?: boolean;
};

export type PriceSize = "normal" | "large";

export type PriceProps = PricePropsBasic & PricePropsDialog & HTMLAttributes<HTMLSpanElement>;

const Wrapper = styled("div")(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    display: "inline-flex",
    lineHeight: 1,
    direction: "ltr /*! @noflip */" as any,
    '&[data-size="large"]': {
        fontSize: 24,
    },
    '[data-type="currency"]': {
        fontSize: "0.6em",
        paddingTop: "0.1em",
        marginInlineEnd: "0.4em",
        color: theme.palette.text.secondary,
    },
    '[data-type="fraction"]': {
        fontSize: "0.5em",
        paddingTop: "0.1em",
    },
    '&[data-hoverable="true"]': {
        cursor: "pointer",
        position: "relative",
        zIndex: 2,
        "&:before": {
            content: '""',
            zIndex: -1,
            borderRadius: theme.shape.borderRadius,
            position: "absolute",
            display: "block",
            top: -2,
            left: -2,
            width: "calc(100% + 2px)",
            height: "calc(100% + 2px)",
            transition: theme.transitions.create("background-color", {
                duration: theme.transitions.duration.short,
            }),
        },
        "&:hover": {
            "&:before": {
                backgroundColor: theme.palette.grey.A200,
            },
        },
    },
}));

const DEFAULT_PARTS_TO_DISPLAY: Intl.NumberFormatPartTypes[] = ["currency", "fraction", "integer", "group"];

export function Price({
    amount,
    language,
    size = "normal",
    parts = DEFAULT_PARTS_TO_DISPLAY,
    currency,
    pricingData,
    isPricingDataDialogOpen,
    onPricingDataDialogOpenChange,
    defaultIsPricingDataDialogOpen,
    ...props
}: PriceProps) {
    const formatter = usePriceFormatter(currency, language);
    const [isDialogOpen, setDialogOpen] = useControlledState(
        isPricingDataDialogOpen,
        defaultIsPricingDataDialogOpen,
        (isOpen) => {
            onPricingDataDialogOpenChange?.(isOpen ?? false);
        }
    );

    const onClick = pricingData
        ? (e: MouseEvent) => {
              e.preventDefault();
              setDialogOpen(true);
          }
        : undefined;
    return (
        <>
            <Wrapper data-size={size} data-hoverable={!!pricingData} onClick={onClick} {...props}>
                {formatter.toElements(amount, parts)}
            </Wrapper>
            {pricingData && (
                <PriceDataDialog
                    open={isDialogOpen ?? false}
                    onClose={() => {
                        setDialogOpen(false);
                    }}
                    pricingData={pricingData}
                />
            )}
        </>
    );
}

export function PriceDataDialog({ pricingData, ...props }: DialogProps & { pricingData: PricingData }) {
    return (
        <Dialog {...props} fullWidth maxWidth="lg">
            <DialogTitle>Pricing data</DialogTitle>
            <DialogContent>
                <Suspense fallback={<div>Loading...</div>}>
                    <JSONTree data={pricingData} theme="colors"></JSONTree>
                </Suspense>
            </DialogContent>
        </Dialog>
    );
}
