import { styled } from "@mui/material/styles";
import React, { ComponentType, CSSProperties, ForwardedRef, forwardRef, HTMLAttributes } from "react";

import { TypographyDocument } from "../TypographyDocument";
import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

type ParagraphAttributes = Omit<HTMLAttributes<HTMLParagraphElement>, "align" | "content">;
const StyledParagraph = styled(TypographyDocument)({
    whiteSpace: "pre-wrap",
});

const DefaultParagraphComponent = forwardRef(function DefaultParagraphComponentInternal(
    props: HTMLAttributes<HTMLParagraphElement>,
    ref: ForwardedRef<HTMLParagraphElement>
) {
    return <TypographyDocument variant="p" {...props} ref={ref} />;
});

export const HbmlParagraphNode = hbmlNodeFactory<
    typeof HbmlElements.PARAGRAPH,
    {
        align?: CSSProperties["textAlign"];
    },
    ParagraphAttributes & { component?: ComponentType<ParagraphAttributes> }
>({
    type: HbmlElements.PARAGRAPH,
    Component: forwardRef(function ComponentInternal(
        { node, style, component = DefaultParagraphComponent, ...props },
        ref: ForwardedRef<HTMLParagraphElement>
    ) {
        const finalStyle = {
            ...style,
        };

        if (node.align) {
            finalStyle.textAlign = node.align;
        }
        return <StyledParagraph as={component} style={finalStyle} ref={ref} {...props} />;
    }),
});
