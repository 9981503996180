import StarIcon from "@mui/icons-material/Star";
import { styled } from "@mui/material/styles";

import { Typography } from "../../components/Typography";

export const ReviewWrapper = styled("div")(({ theme }) => ({
    display: "inline",
    alignItems: "center",
    gap: theme.spacing(0.5),
}));

export const Star = styled(StarIcon)(({ theme }) => ({
    height: 12,
    width: 12,
    fill: "#F2C94C",
    position: "relative",
    top: -1,
    verticalAlign: "middle",
    marginRight: theme.spacing(0.5),
}));

export const ReviewRating = styled(Typography)({
    display: "inline-flex",
    whiteSpace: "nowrap",
});

export const getReviewRating = (reviewRating: number, reviewCount: number) => {
    return `${reviewRating.toFixed(2)} (${reviewCount})`;
};

type ProductReviewsInfoV2Props = {
    reviewRating: number;
    reviewCount: number;
};

export const ProductReviewsInfoV2 = (props: ProductReviewsInfoV2Props) => {
    const { reviewRating, reviewCount } = props;
    const review = getReviewRating(reviewRating, reviewCount);

    return (
        <ReviewWrapper>
            <Star data-star-icon="true" />
            <ReviewRating data-review-rating="true" component="span" variant="body" size="small">
                {review}
            </ReviewRating>
        </ReviewWrapper>
    );
};
