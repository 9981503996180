import { ElementType } from "./ElementTypes";
import { ConsumerTripSchema, TextNodeSchema } from "./schemas";
import { HbmlElementNodeConsumerTrip, HbmlElementNode, HbmlNode, HbmlTextNode } from "./types";

export function isTextNode(node: unknown): node is HbmlTextNode {
    return TextNodeSchema.safeParse(node).success;
}

export const isConsumerTripHbmlNode = (node: unknown): node is HbmlElementNodeConsumerTrip => {
    return ConsumerTripSchema.safeParse(node).success;
};

export function isElementNode(node: unknown): node is HbmlElementNode {
    return typeof node === "object" && node !== null && "children" in node && "type" in node;
}

export function isElementNodeOfType<T extends ElementType>(
    node: unknown,
    type: T
): node is HbmlElementNode & { type: T } {
    return isElementNode(node) && node.type === type;
}

export function isEmptyElement(node: HbmlElementNode) {
    return node.children.every((node) => isTextNode(node) && node.text.trim() === "");
}

export function isEmptyNodeElement(node: HbmlNode): boolean {
    if (isElementNodeOfType(node, "paragraph")) {
        if (isEmptyElement(node as HbmlElementNode)) {
            return true;
        }
    }

    if (isElementNodeOfType(node, "section")) {
        return isEmptyContent(node.children);
    }

    if (isTextNode(node)) {
        return node.text.trim() === "";
    }

    return false;
}

export function isEmptyContent(nodes: HbmlNode[]): boolean {
    if (!Array.isArray(nodes) || nodes.length === 0) {
        return true;
    }

    return nodes.every((node) => isEmptyNodeElement(node));
}
