import { ForwardedRef, forwardRef, HTMLAttributes } from "react";

import { TypographyDocument } from "../TypographyDocument";
import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

export const HbmlLinkNode = hbmlNodeFactory<
    typeof HbmlElements.LINK,
    {
        url?: string;
        target?: string;
    },
    Omit<HTMLAttributes<HTMLAnchorElement>, "content">
>({
    type: HbmlElements.LINK,
    Component: forwardRef(function ComponentInternal({ node, ...props }, ref: ForwardedRef<HTMLAnchorElement>) {
        return <TypographyDocument variant="a" href={node.url} target={node.target} {...props} ref={ref} />;
    }),
});
