import { Range, enchant } from "@pallad/range";
import type { Duration } from "dayjs/plugin/duration";

import { dayjs } from "@holibob-packages/dayjs";
import { Language } from "@holibob-packages/language";

export function durationFromString(language: Language, input: string) {
    if (!input || input === "P0D") {
        return;
    }
    return dayjs.duration(input).locale(language.codeShort);
}

export function formatDurationToTextAlternative(duration: Duration) {
    const formatted = duration.humanize();
    return formatted
        .replace(/(^a) (.+)/, "1 $2")
        .replace(/(^an) (.+)/, "1 $2")
        .replace(/(^un) (.+)/, "1 $2");
}

export const formatDurationRange = (range: Range<Duration>) => {
    return enchant(range).map({
        end: ({ end }) => formatDurationToTextAlternative(end),
        start: ({ start }) => formatDurationToTextAlternative(start),
        full: ({ start, end }) => `${formatDurationToTextAlternative(start)} - ${formatDurationToTextAlternative(end)}`,
    });
};

export function formatDurationFromString(
    language: Language,
    minDuration: string | undefined,
    maxDuration: string | undefined
) {
    const finalMinDuration = minDuration ? durationFromString(language, minDuration) : undefined;
    const finalMaxDuration = maxDuration ? durationFromString(language, maxDuration) : undefined;

    const rangeInput: Range.Tuple<Duration> =
        minDuration === maxDuration ? [finalMinDuration!] : [finalMinDuration!, finalMaxDuration];

    try {
        const range = Range.fromArray<Duration>(rangeInput, (a, b) => a.milliseconds() - b.milliseconds());
        return formatDurationRange(range);
    } catch {
        /* empty */
    }

    return null;
}
