import { useViewer, Viewer } from "hooks/useViewer";
import get from "lodash/get";
import set from "lodash/set";

export type EntityConfiguration = Viewer["entityConfiguration"][number];

export type EntityConfigurationIcon = EntityConfiguration["icon"];

export function useEntityConfiguration(): EntityConfiguration[] {
    return useViewer().entityConfiguration;
}

/**
 * An object like:
 * {
 *    EMAIL_ENTITY_TEMPLATE_ID: {
 *      type: "EMAIL_ENTITY_TEMPLATE_ID",
 *      subType: "",
 *      value: "702f9137-dc14-11ec-884c-06c3d4e2e001"
 *    },
 *    CAROUSEL_IMAGE_VAULT_ID: {
 *      1: {
 *          type: "CAROUSEL_IMAGE_VAULT_ID",
 *          subType: "1",
 *          value: "base64",
 *      },
 *      ...
 *    }
 * }
 */
type EntityConfigurationMapped = Record<string, EntityConfiguration | Record<string, EntityConfiguration>>;
function useEntityConfigurationMapped(): EntityConfigurationMapped {
    const viewer = useViewer();

    return getEntityConfigurationMapped(viewer);
}

function getEntityConfigurationMapped(viewer: Viewer): EntityConfigurationMapped {
    const configs = viewer.entityConfiguration;

    return configs.reduce<EntityConfigurationMapped>((acc, config) => {
        const { type, subType } = config;
        const key = [type];
        if (subType) key.push(subType);
        set(acc, key, config);
        return acc;
    }, {});
}

export function useEntityConfigurationByTypes(...types: string[]) {
    const configs = useEntityConfiguration();

    return configs.filter((config) => types.includes(config.type));
}

export function useEntityConfigurationByKey(key: string) {
    const mappedConfigs = useEntityConfigurationMapped();
    return get(mappedConfigs, key);
}

export function useEntityConfigurationValueByKey<T extends string | boolean>(key: string, defaultValue: T): T;
export function useEntityConfigurationValueByKey<T extends string | boolean>(key: string): T | undefined;
export function useEntityConfigurationValueByKey<T extends string | boolean>(
    key: string,
    defaultValue?: T
): T | undefined {
    const viewer = useViewer();
    return getEntityConfigurationValueByKey<T>(viewer, key, defaultValue!);
}

export function getEntityConfigurationValueByKey<T extends string | boolean>(
    viewer: Viewer,
    key: string,
    defaultValue: T
): T;
export function getEntityConfigurationValueByKey<T extends string | boolean>(
    viewer: Viewer,
    key: string
): T | undefined;
export function getEntityConfigurationValueByKey<T extends string | boolean>(
    viewer: Viewer,
    key: string,
    defaultValue?: T
): T | undefined {
    const mappedConfigs = getEntityConfigurationMapped(viewer);
    const config = get(mappedConfigs, key) as EntityConfiguration | undefined;
    const value = config?.value as T | undefined;

    // TODO: wrong use of types here, as this will fail for useEntityConfigurationValueByKey<string> if value === "true"
    if (typeof value === "string") {
        if (value.toLowerCase() === "true") {
            return true as T;
        }
        if (value.toLowerCase() === "false") {
            return false as T;
        }
    }

    return value ?? defaultValue;
}

export default useEntityConfiguration;
