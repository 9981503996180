import { Skeleton } from "@mui/material";
import { styled } from "@mui/material/styles";

import { AssetUrl } from "@holibob-packages/vault";

import { Typography } from "../components";
import { HoverableCard } from "../components/HoverableCard";
import { ImageTile, ImageTileOverlayFooter } from "../components/ImageTile";
import { Link, LinkProps } from "../navigation/Link";

export type CurationCardProps = {
    image?: AssetUrl;
    name: string;
    imageSource?: string;
} & LinkProps;

const MAX_WIDTH = 500;
const HEIGHT = 300;

export function CurationCard({ image, name, ...props }: CurationCardProps) {
    return (
        <Link {...props} passHref>
            <HoverableCardStyled>
                <ImageTile
                    imageAssetUrl={image}
                    alt={name}
                    height={HEIGHT}
                    maxWidth={MAX_WIDTH}
                    hoverZoomScale={1.2}
                    hoverOpacity={0.8}
                >
                    <ImageTileOverlayFooterStyled>
                        <Typography variant="title" color="light" size="large">
                            {name}
                        </Typography>
                    </ImageTileOverlayFooterStyled>
                </ImageTile>
            </HoverableCardStyled>
        </Link>
    );
}

const HoverableCardStyled = styled(HoverableCard)({ maxWidth: MAX_WIDTH });
const ImageTileOverlayFooterStyled = styled(ImageTileOverlayFooter)(({ theme }) => ({ padding: theme.spacing(2) }));

export function CurationCardSkeleton({ width, height }: { width?: number; height?: number }) {
    return <Skeleton variant="rectangular" width={width} height={height ?? HEIGHT} />;
}
