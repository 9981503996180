import { auth } from "config/shared";
import { createContext, useMemo } from "react";

export type Config = {
    isProduction: boolean;
    isLocal: boolean;
    isStaging: boolean;
    auth: typeof auth;
};
export const ConfigContext = createContext<Config | null>(null);

export type ConfigProviderProps = {
    children: React.ReactNode;
    host: string;
};

export function ConfigProvider({ host, children }: ConfigProviderProps) {
    const config = useMemo(() => {
        const isLocal = host === "localhost:3000" || host.endsWith("ngrok.io") || host.endsWith("ngrok-free.app");
        const isStaging = host.split(".").includes("staging");

        return {
            isProduction: !isLocal && !isStaging,
            isLocal,
            isStaging,
            auth,
        };
    }, [host]);

    return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
}
