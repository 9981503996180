import { useEntityConfigurationValueByKey } from "./useEntityConfiguration";
import { useFavouriteProduct } from "./useFavouriteProducts";

export function useFavouriteButton(productId: string, isFavouriteProduct?: boolean) {
    const [isFavourite, setIsFavourite] = useFavouriteProduct(productId, isFavouriteProduct);
    const canUseFavourite = !!useEntityConfigurationValueByKey("SETTING.CAN_USE_FAVOURITE");
    return {
        isAvailable: canUseFavourite,
        isFavourite,
        setIsFavourite,
    };
}
