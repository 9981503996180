import UpdateIcon from "@mui/icons-material/Update";

import { LabelValue, LabelValueProps } from "../../components/LabelValue";
import { useLanguage } from "../../hooks/useLanguage";
import { useProductTranslation } from "../../hooks/useProductTranslation";
import { formatDurationFromString } from "../../utils/formatters/formatDuration";

export type ProductDurationMetaData = {
    minDuration: string | null;
    maxDuration: string | null;
    showLabel?: boolean;
    size?: LabelValueProps["size"];
};

export function ProductDurationMetaData({
    minDuration,
    maxDuration,
    showLabel = true,
    ...props
}: ProductDurationMetaData) {
    const language = useLanguage();

    const [t] = useProductTranslation();
    const label = t("label.duration");

    const formatted = formatDurationFromString(language, minDuration ?? undefined, maxDuration ?? undefined);
    if (!formatted) {
        return null;
    }
    return (
        <LabelValue label={showLabel ? label : undefined} Icon={UpdateIcon} {...props}>
            {formatted}
        </LabelValue>
    );
}
