import { useCurrency, useLanguage } from ".";

const STYLE = "currency";
export function useFormattedPrice(amount: number, options: Intl.NumberFormatOptions = {}) {
    const languageFromContext = useLanguage();
    const currencyFromContext = useCurrency();

    return new Intl.NumberFormat(languageFromContext.code, {
        ...options,
        style: STYLE,
        currency: options.currency ?? currencyFromContext,
    }).format(amount);
}
