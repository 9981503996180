export class CSSVariable {
    readonly name: string;

    constructor(name: `--${string}`) {
        this.name = "--" + name.replace(/^--/, "");
    }

    get reference() {
        return `var(${this.name})`;
    }

    toString() {
        return this.name;
    }
}
