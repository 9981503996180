import { styled } from "@mui/material/styles";
import { AllHTMLAttributes, ForwardedRef, forwardRef } from "react";
import { ElementOf } from "ts-essentials";

export type TypographyDocumentVariant = ElementOf<typeof TypographyDocumentVariantValues>;
export const TypographyDocumentVariantValues = [
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "blockquote",
    "ol",
    "ul",
    "li",
    "em",
    "strong",
    "table",
    "a",
    "span",
] as const;

export type TypographyDocumentProps = {
    variant?: TypographyDocumentVariant;
    isSpecialFont?: boolean;
} & Omit<AllHTMLAttributes<HTMLElement>, "as">;

const Wrapper = styled("p")(({ theme }) => ({
    all: "unset",
    display: "revert",
    lineHeight: "1.5",
    fontFamily: theme.typography.fontFamily,
    '&[data-is-special-font="true"]': {
        fontFamily: theme.typography.specialFontFamily,
    },
    "h1&, h2&, h3&, h4&, h5&, h6&": {
        marginBlockEnd: "1rem",
        fontWeight: "bold",
    },
    "p&": {
        marginBlockStart: "1rem",
        marginBlockEnd: "1rem",
    },
    "ul&, ol&": {
        marginInlineStart: theme.spacing(0.5),
        paddingInlineStart: theme.spacing(2),
        "> li": {
            marginBlockStart: theme.spacing(1),
            marginBlockEnd: theme.spacing(1),
        },
    },
    "ol&": {
        listStyleType: "decimal",
    },
    "ul&": {
        listStyleType: "disc",
        listStylePosition: "outside",
    },
    "h1&": {
        fontSize: "2.8rem",
        marginBlockEnd: "1.5rem",
        fontWeight: theme.typography.fontWeightMedium,
    },
    "h2&": {
        fontSize: "2.4rem",
        fontWeight: theme.typography.fontWeightMedium,
    },
    "h3&": {
        fontSize: "2rem",
        fontWeight: theme.typography.fontWeightMedium,
    },
    "h4&": {
        fontSize: "1.6rem",
    },
    "h5&": {
        fontSize: "1.4rem",
    },
    "h6&": {
        fontSize: "1rem",
        textTransform: "uppercase",
    },
    "blockquote&": {
        marginBlockStart: theme.spacing(1),
        marginBlockEnd: theme.spacing(1),
        paddingInlineStart: theme.spacing(2),
        paddingInlineEnd: theme.spacing(2),
        paddingBlockStart: theme.spacing(1),
        paddingBlockEnd: theme.spacing(1),
        borderLeft: `2px solid ${theme.palette.text.secondary}`,
    },
    "em&": {
        fontStyle: "italic",
    },
    "strong&": {
        fontWeight: "bold",
    },
    "a&": {
        cursor: "revert",
        color: theme.palette.primary.main,
        textDecoration: "none",
        "&:hover": {
            color: theme.palette.primary.dark,
            textDecoration: "underline",
        },
    },
    "img&, picture&": {
        marginBlockStart: "1rem",
        marginBlockEnd: "1rem",
    },
    "table&": {
        borderCollapse: "collapse",
        border: `1px solid ${theme.palette.divider}`,
        marginBlockStart: theme.spacing(1),
        marginBlockEnd: theme.spacing(1),

        th: {
            fontWeight: "bold",
        },

        "th,td": {
            padding: theme.spacing(1),
            border: `1px solid ${theme.palette.divider}`,
        },
    },
}));

export const TypographyDocumentComponents = Object.fromEntries(
    TypographyDocumentVariantValues.map((x) => {
        return [x, Wrapper.withComponent(x)];
    })
);

// eslint-disable-next-line react/display-name
export const TypographyDocument = forwardRef(
    ({ variant = "p", isSpecialFont, ...props }: TypographyDocumentProps, ref: ForwardedRef<HTMLElement>) => {
        const Component = TypographyDocumentComponents[variant];
        return <Component data-is-special-font={isSpecialFont} {...props} ref={ref as any} />;
    }
);
