export type SrcFactory = (input: SrcFactoryInput) => string;
export type SrcFactoryInput = { width: number; height?: number };

export type SrcSetOptions = {
    srcFactory: SrcFactory;
    height?: number;
};

export function computeImageSrcSetProperties(sizes: number[], options: SrcSetOptions) {
    const { srcFactory, height } = options;
    const biggestSize = Math.max(...sizes);

    return {
        src: srcFactory({ width: biggestSize, height }),
        srcSet: computeSrcSet(sizes, options),
    };
}

export function computeSrcSet(sizes: number[], { srcFactory, height }: SrcSetOptions) {
    const sortedSizes = sizes.sort((a, b) => a - b);
    const srcList: string[] = [];

    for (const width of sortedSizes) {
        const src = srcFactory({ width, height });
        const finalSrc = `${src} ${width}w`;
        srcList.push(finalSrc);
    }

    return srcList.join(",");
}
