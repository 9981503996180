import { styled } from "@mui/material/styles";
import { CSSProperties, useState } from "react";

import { AssetUrl, assetUrlToVaultUrl } from "@holibob-packages/vault";

import { ResponsiveImage } from "./ResponsiveImage";

type ImageSizes = { maxWidth: number } | { srcSetSizes: number[]; sizes: string };

export type ImageTileProps = {
    alt: string;
    height: number;
    minWidth?: number;
    imageAssetUrl?: AssetUrl;
    children?: React.ReactNode;
    hoverZoomScale?: number;
    hoverOpacity?: number;
} & ImageSizes;

export const ImageTile = ({
    imageAssetUrl,
    alt,
    height,
    minWidth,
    hoverZoomScale,
    hoverOpacity,
    children,
    ...props
}: ImageTileProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const imageSizeFactor = hoverZoomScale ?? 1;
    const sizesProps =
        "maxWidth" in props
            ? { width: props.maxWidth * imageSizeFactor }
            : { srcSetSizes: props.srcSetSizes, sizes: props.sizes };
    const maxWidth = "maxWidth" in props ? props.maxWidth : undefined;

    return (
        <ImageContainer
            style={{ height, maxWidth, minWidth }}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            {...props}
        >
            {imageAssetUrl && (
                <ResponsiveImageStyled
                    data-on-hover-zoom-effect={typeof hoverZoomScale === "number"}
                    data-on-hover-opacity-effect={typeof hoverOpacity === "number"}
                    data-is-hovered={isHovered}
                    src={assetUrlToVaultUrl(imageAssetUrl).toString()}
                    height={height * imageSizeFactor}
                    alt={alt}
                    ImageComponent={ImageComponent}
                    loading="lazy"
                    style={
                        {
                            "--on-hover-opacity": hoverOpacity,
                            "--on-hover-zoom-scale": hoverZoomScale,
                        } as CSSProperties
                    }
                    {...sizesProps}
                />
            )}
            {children}
        </ImageContainer>
    );
};

const ImageContainer = styled("div")(({ theme }) => ({
    position: "relative",
    overflow: "hidden",
    backgroundColor: theme.palette.grey.A200,
}));

const ImageComponent = styled("img")({
    objectFit: "cover",
    display: "block",
    height: "100%",
    width: "100%",
});

const ResponsiveImageStyled = styled(ResponsiveImage)(({ theme }) => ({
    position: "relative",
    display: "block",
    height: "100%",
    width: "100%",

    "&[data-on-hover-zoom-effect='true']": {
        cursor: "pointer",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.short,
        }),
        "&[data-is-hovered='true']": {
            transform: `scale(var(--on-hover-zoom-scale))`,
        },
    },

    "&[data-on-hover-opacity-effect='true']": {
        cursor: "pointer",
        "&[data-is-hovered='true']": {
            opacity: "var(--on-hover-opacity)",
        },
        "&:after": {
            transition: theme.transitions.create("opacity", {
                duration: theme.transitions.duration.short,
            }),
            position: "absolute",
            content: '""',
            width: "100%",
            height: "100%",
            top: 0,
            opacity: 0,
            left: 0,
            backgroundColor: theme.palette.common.white,
        },
    },
}));

type ImageTileOverlayProps = {
    children?: React.ReactNode;
    backgroundGradient?: boolean;
};

const ImageTileOverlay = ({ children, backgroundGradient = true, ...props }: ImageTileOverlayProps) => {
    return (
        <ImageTileOverlayWrapper {...props} data-background-gradient={backgroundGradient}>
            {children}
        </ImageTileOverlayWrapper>
    );
};
const ImageTileOverlayWrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "flex-start",
    position: "absolute",
    top: 0,
    zIndex: 2,
    boxSizing: "border-box",
    height: "100%",
    width: "100%",
    pointerEvents: "none",
    "& *": {
        pointerEvents: "auto",
    },
});

export const ImageTileOverlayHeader = styled(ImageTileOverlay)(({ theme }) => ({
    justifyContent: "start",
    borderRadius: theme.roundness,
    "&[data-background-gradient='true']": {
        background: `linear-gradient(to top, #ffffff00, rgba(0,0,0, 0.6))`,
        backgroundSize: "100% 40%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
    },
}));

export const ImageTileOverlayFooter = styled(ImageTileOverlay)({
    justifyContent: "end",
    "&[data-background-gradient='true']": {
        background: `linear-gradient(to bottom, #ffffff00, rgba(0,0,0, 0.6))`,
        backgroundSize: "100% 40%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
    },
});
