import { styled } from "@mui/material/styles";
import { Children, forwardRef, ForwardedRef } from "react";

import { ScrollableContainer, ScrollableContainerProps } from "../components/ScrollableContainer";
import { ScrollableContainerItem } from "../components/ScrollableContainerItem";

const SLIDE_MIN_WIDTH = (container: HTMLElement) => container.clientWidth * 0.7;

const CurationItemWrapper = styled(ScrollableContainerItem)({
    width: 250,
});

export type CurationsContainerSliderProps = ScrollableContainerProps;
export const CurationsContainerSlider = forwardRef(function CurationsContainerSlider(
    { children, ...props }: CurationsContainerSliderProps,
    ref: ForwardedRef<HTMLDivElement>
) {
    return (
        <ScrollableContainer slideMinWidth={SLIDE_MIN_WIDTH} ref={ref} {...props}>
            {Children.map(children, (node, index) => {
                return <CurationItemWrapper key={index}>{node}</CurationItemWrapper>;
            })}
        </ScrollableContainer>
    );
});
