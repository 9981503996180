import { Language } from "@holibob-packages/language";

import { getPriceFormatter } from "../utils/formatters/formatPrice";
import { useCurrency } from "./useCurrency";
import { useLanguage } from "./useLanguage";

export const usePriceFormatter = (currency?: string | null, language?: Language) => {
    const languageFromHook = useLanguage();
    const currencyFromHook = useCurrency();
    return getPriceFormatter((language ?? languageFromHook).code, currency ?? currencyFromHook);
};
