import React, { ForwardedRef, forwardRef, HTMLAttributes } from "react";

import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

export const HbmlTableRowNode = hbmlNodeFactory<
    typeof HbmlElements.TABLE_ROW,
    Record<string, unknown>,
    Omit<HTMLAttributes<HTMLTableRowElement>, "content">
>({
    type: HbmlElements.TABLE_ROW,
    Component: forwardRef(function HbmlTableRowNodeInternalComponent(
        { node: _node, ...props },
        ref: ForwardedRef<HTMLTableRowElement>
    ) {
        return <tr ref={ref} {...props} />;
    }),
});
