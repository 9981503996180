import useConfig from "hooks/useConfig";
import { useConsumerTripInfo } from "hooks/useConsumerTripInfo";
import useViewer from "hooks/useViewer";
import { useMemo } from "react";

const GA4_TEST_MEASUREMENT_ID = "G-CXDYGWQ91K";

export function useDefaultConfig() {
    const config = useConfig();
    const viewer = useViewer();
    const { consumerTripId } = useConsumerTripInfo();

    const { isLocal } = config;
    const isAdminTraffic = viewer.securityPrincipal?.user?.name.toLowerCase().includes("admin");

    return useMemo(
        () => ({
            send_page_view: false,
            debug_mode: isLocal, // allows use of Configure > DebugView
            cookie_flags: "SameSite=None;Secure", // allows GA4 to work in an iframe
            traffic_type: isAdminTraffic ? "admin" : "normal",
            user_id: consumerTripId,
        }),
        [isAdminTraffic, isLocal, consumerTripId]
    );
}

export function useDistributionChannelGA4MeasurementId() {
    const viewer = useViewer();
    return viewer.distributionChannel?.ga4MeasurementId;
}

export function useDistributionChannelGoogleTagManagerId() {
    const viewer = useViewer();
    return viewer.distributionChannel?.googleTagManagerId;
}

export function useGoogleTagManagerId() {
    const config = useConfig();
    const { isProduction } = config;
    const distributionChannelGoogleTagManagerId = useDistributionChannelGoogleTagManagerId();

    return isProduction ? distributionChannelGoogleTagManagerId : null;
}

export function useGA4MeasurementId() {
    const config = useConfig();
    const { isLocal, isStaging } = config;
    const distributionChannelGA4MeasurementId = useDistributionChannelGA4MeasurementId();

    if (isStaging) return null;
    if (isLocal) return GA4_TEST_MEASUREMENT_ID;

    return distributionChannelGA4MeasurementId;
}
