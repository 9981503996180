import { styled } from "@mui/material/styles";

import { HbmlElementNode } from "@holibob-packages/hbml";

import { HbmlRendererNodes } from "../HbmlRenderer";
import { TypographyDocument } from "../TypographyDocument";

const Wrapper = styled(TypographyDocument)({
    '&[data-with-marker="false"]': {
        listStyle: "none",
        marginLeft: "-1.2em",
    },
});

export function HbmlListItemNode({ content }: { content: HbmlElementNode & { withMarker?: boolean } }) {
    return (
        <Wrapper data-with-marker={content.withMarker ?? true} variant="li">
            <HbmlRendererNodes content={content.children} />
        </Wrapper>
    );
}
