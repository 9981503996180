import { createContext, useContext } from "react";
import { ConsumerTripCookieData } from "utils/consumerTrip/consumerTripCookie";

export type ConsumerTripInfo = {
    consumerTripId: string;
    consumerId: string;
};

export const ConsumerTripInfoContext = createContext<ConsumerTripInfo | null>(null);

export function useConsumerTripInfo(): ConsumerTripInfo {
    return useContext(ConsumerTripInfoContext)!;
}

type ConsumerTripInfoProviderProps = {
    children: React.ReactNode;
    consumerTripCookie: ConsumerTripCookieData;
};

export function ConsumerTripInfoProvider({ children, consumerTripCookie }: ConsumerTripInfoProviderProps) {
    const consumerTripId = consumerTripCookie.consumerTrip.id;
    const consumerId = consumerTripCookie.consumer.id;
    return (
        <ConsumerTripInfoContext.Provider value={{ consumerTripId, consumerId }}>
            {children}
        </ConsumerTripInfoContext.Provider>
    );
}
