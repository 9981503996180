import { Button as MuiButton, ButtonProps as MuiButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import { getResponsiveTypographyStyles } from "./Typography";

// see for button typography sizing
// https://m3.material.io/styles/typography/applying-type#af6eb002-9cbb-4b64-bce6-1315d2252364

export type ButtonProps = {
    children: React.ReactNode;
    color?: MuiButtonProps["color"] | "light";
} & Omit<MuiButtonProps, "children" | "color">;
export const Button = ({ children, color, ...props }: ButtonProps) => {
    const muiColor = color === "light" ? undefined : color;
    return (
        <ButtonStyled data-color={color} color={muiColor} disableElevation={true} {...props}>
            {children}
        </ButtonStyled>
    );
};

const ButtonStyled = styled(MuiButton)(({ theme }) => ({
    textTransform: "none",
    ...getResponsiveTypographyStyles(theme, "label", "large"),
    "&[data-color='light']": {
        color: "white",
        borderColor: "white",
    },
    "&[data-color='subtle']": {
        color: theme.palette.text.secondary,
        borderColor: "white",
    },
}));
