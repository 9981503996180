import { z } from "zod";

import { ElementTypes } from "../ElementTypes";
import { HbmlNode, HbmlTextNode } from "../types";
import {
    AlignAttributeSchema,
    BooleanAttributeSchema,
    ButtonActionSchema,
    ButtonColorSchema,
    ButtonSizeSchema,
    ButtonVariantSchema,
    ColorIndexSchema,
    ShadeSchema,
    TextNamedColorSchema,
    UrlOrUrlPartsAttributeSchema,
} from "./common";
import { ThemeNamedColorSchema } from "./common/ThemeNamedColorSchema";

export type Children =
    | {
          type: string;
          children: HbmlNode[];
          [key: string]: unknown;
      }
    | HbmlTextNode;

export const ChildrenSchema = z.array(z.lazy(() => NodeSchema)).nonempty() as z.ZodType<Children[]>;

export const ConsumerTripSchema = z.object({
    type: z.literal("consumer-trip"),
    curationId: z.string().optional(),
    hero: z
        .object({
            images: z.array(z.string()),
        })
        .optional(),
    children: ChildrenSchema,
    refId: z.string().optional(),
});

export const TextNodeSchema = z.object({
    text: z.string(),
    bold: BooleanAttributeSchema.optional(),
    italic: BooleanAttributeSchema.optional(),
    underline: BooleanAttributeSchema.optional(),
    code: BooleanAttributeSchema.optional(),
    color: z.union([TextNamedColorSchema, ColorIndexSchema, z.string()]).optional(),
    isSpecialFont: BooleanAttributeSchema.optional(),
    shade: ShadeSchema.optional(),
    refId: z.string().optional(),
});

export const ParagraphSchema = z.object({
    type: z.literal("paragraph"),
    align: AlignAttributeSchema.optional(),
    children: ChildrenSchema,
    refId: z.string().optional(),
});

export const NumberedListSchema = z.object({
    type: z.literal("numbered-list"),
    children: ChildrenSchema,
    refId: z.string().optional(),
});

export const ListItemSchema = z.object({
    type: z.literal("list-item"),
    children: ChildrenSchema,
    refId: z.string().optional(),
});

export const LinkSchema = z.object({
    type: z.literal("link"),
    url: UrlOrUrlPartsAttributeSchema,
    children: ChildrenSchema,
    target: z.union([z.literal("_self"), z.literal("_blank")]),
    refId: z.string().optional(),
});

export type HbmlLinkNode = z.infer<typeof LinkSchema>;

export const HeadingTwoSchema = z.object({
    type: z.literal("heading-two"),
    align: AlignAttributeSchema.optional(),
    children: ChildrenSchema,
    refId: z.string().optional(),
});

export const HeadingThreeSchema = z.object({
    type: z.literal("heading-three"),
    align: AlignAttributeSchema.optional(),
    children: ChildrenSchema,
    refId: z.string().optional(),
});

export const HeadingOneSchema = z.object({
    type: z.literal("heading-one"),
    align: AlignAttributeSchema.optional(),
    children: ChildrenSchema,
    refId: z.string().optional(),
});

export const ButtonSchema = z.object({
    type: z.literal("button"),
    size: ButtonSizeSchema.optional(),
    label: z.string(),
    variant: ButtonVariantSchema.optional(),
    action: ButtonActionSchema.optional(),
    color: z.union([ThemeNamedColorSchema, ButtonColorSchema]).optional(),
    children: ChildrenSchema,
    refId: z.string().optional(),
});

export const BulletedListSchema = z.object({
    type: z.literal("bulleted-list"),
    children: ChildrenSchema,
    refId: z.string().optional(),
});

export const DividerSchema = z.object({
    type: z.literal("divider"),
    children: ChildrenSchema,
    style: z.any(),
    refId: z.string().optional(),
});

export type HbmlBulletedListNode = z.output<typeof BulletedListSchema>;

const FALLBACK_TYPE_LITERALS = [
    z.literal(ElementTypes.MEDIA_OBJECT),
    z.literal(ElementTypes.MEDIA_OBJECT_MEDIA),
    z.literal(ElementTypes.MEDIA_OBJECT_CONTENT),
    z.literal(ElementTypes.RAW_TEXT),
    z.literal(ElementTypes.VAULT_IMAGE),
    z.literal(ElementTypes.HERO),
    z.literal(ElementTypes.CURATIONS_V2),
    z.literal(ElementTypes.CURATION_PRODUCTS),
    z.literal(ElementTypes.CURATION_PRODUCTS_V2),
    z.literal(ElementTypes.HERO_SUBTITLE),
    z.literal(ElementTypes.HERO_TITLE),
    z.literal(ElementTypes.HERO_DESCRIPTION),
    z.literal(ElementTypes.TABLE),
    z.literal(ElementTypes.TABLE_ROW),
    z.literal(ElementTypes.TABLE_CELL),
    z.literal(ElementTypes.SOCIAL_LINKS),
    z.literal(ElementTypes.HEADER_NAVIGATION),
    z.literal(ElementTypes.LIST_ITEM_CONTENT),
    z.literal(ElementTypes.BLOCKQUOTE),
    z.literal(ElementTypes.SECTION),
    z.literal(ElementTypes.STACK),
    z.literal(ElementTypes.STACK_ITEM),
    z.literal(ElementTypes.SECTION_HEADER),
    z.literal(ElementTypes.ELFSIGHT_EMBED),
    z.literal(ElementTypes.FAQ),
    z.literal(ElementTypes.FAQ_ITEM),
    z.literal(ElementTypes.FAQ_ITEM_TITLE),
    z.literal(ElementTypes.FAQ_ITEM_CONTENT),
    z.literal(ElementTypes.ICON),
    z.literal(ElementTypes.HTML),
    z.literal(ElementTypes.POWERED_BY_HOLIBOB),
    z.literal(ElementTypes.UPDATE_COOKIE_CONSENT),
] as const;

const FALLBACK_TYPES = FALLBACK_TYPE_LITERALS.map((x) => {
    return z
        .object({
            type: x,
            children: ChildrenSchema,
            refId: z.string().optional(),
        })
        .passthrough();
});

const DEFINED_ELEMENT_SCHEMAS = [
    ParagraphSchema,
    HeadingOneSchema,
    DividerSchema,
    HeadingTwoSchema,
    HeadingThreeSchema,
    ButtonSchema,
    NumberedListSchema,
    ListItemSchema,
    BulletedListSchema,
    LinkSchema,
    ConsumerTripSchema,
] as const;

export const ElementSchema = z.discriminatedUnion("type", [...DEFINED_ELEMENT_SCHEMAS, ...FALLBACK_TYPES]);

export const NodeSchema = z.union([ElementSchema, TextNodeSchema]);
