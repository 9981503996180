import useResizeObserver from "@react-hook/resize-observer";
import { RefObject, useCallback, useEffect, useState } from "react";

function getMaxScrollForElement(element: HTMLElement) {
    return element.scrollWidth - element.clientWidth;
}

function computeScrollingState(element: HTMLElement): ScrollingState {
    const maxScrollAvailable = getMaxScrollForElement(element);
    const currentScroll = element.scrollLeft;
    // This "-1" is needed to prevent issues with rendering accuracy - happened on curation page for me
    const isScrollAvailable = element.scrollWidth - 1 > element.clientWidth;
    return {
        isAvailable: isScrollAvailable,
        isBackAvailable: isScrollAvailable && currentScroll > 0,
        isForwardAvailable: isScrollAvailable && currentScroll < maxScrollAvailable,
    };
}

function isScrollingStateEqual(a: ScrollingState, b: ScrollingState) {
    return (
        a.isAvailable === b.isAvailable &&
        a.isBackAvailable === b.isBackAvailable &&
        a.isForwardAvailable === b.isForwardAvailable
    );
}

function* itemsPositions(container: HTMLElement) {
    const containerRect = container.getBoundingClientRect();
    const currentScroll = container.scrollLeft;
    const items = Array.from(container.querySelectorAll('[data-ref="scrollable-item"]'));

    for (const item of items) {
        const itemRect = item.getBoundingClientRect();
        yield itemRect.left + itemRect.width / 2 + currentScroll - containerRect.left;
    }
}

function* slidesScrollPositions(
    container: HTMLElement,
    slideMinWidth: ScrollableStateOptionsSlideMinWidth | undefined
) {
    yield 0;
    const containerWidth = container.offsetWidth;
    const maxScroll = getMaxScrollForElement(container);
    const containerCenter = containerWidth / 2;
    const finalSlideMinWidth = slideMinWidth instanceof Function ? slideMinWidth(container) : slideMinWidth ?? 0;
    let previousPosition = 0;
    for (const itemPosition of itemsPositions(container)) {
        const scrollPositionForItem: number = itemPosition - containerCenter;
        if (scrollPositionForItem < 0) {
            continue;
        } else if (scrollPositionForItem > maxScroll) {
            break;
        }
        if (scrollPositionForItem - previousPosition > finalSlideMinWidth) {
            yield scrollPositionForItem;
            previousPosition = scrollPositionForItem;
        }
    }
    yield maxScroll;
}

type ScrollingState = {
    isAvailable: boolean;
    isBackAvailable: boolean;
    isForwardAvailable: boolean;
};
type Direction = "back" | "forward";

function scrollInDirection(container: HTMLElement, direction: Direction, options: ScrollableStateOptions) {
    const scrollPosition = getNextPositionToScroll(container, direction, options);
    if (scrollPosition !== undefined) {
        container.scrollTo({ left: scrollPosition, behavior: "smooth" });
    }
}

function getCurrentSlideIndex(container: HTMLElement, slides: number[]) {
    let previousDistance = Number.POSITIVE_INFINITY;

    const currentPosition = container.scrollLeft;
    for (const [index, slide] of slides.entries()) {
        if (slide === currentPosition) {
            return index;
        }
        const currentDistance = Math.abs(slide - currentPosition);
        if (currentDistance > previousDistance) {
            return index - 1;
        }
        previousDistance = currentDistance;
    }

    return slides.length - 1;
}

function getNextPositionToScroll(
    container: HTMLElement,
    direction: Direction,
    options: ScrollableStateOptions
): number | undefined {
    const slides = Array.from(slidesScrollPositions(container, options.slideMinWidth));
    const currentSlideIndex = getCurrentSlideIndex(container, slides);
    const nextSlidePosition = currentSlideIndex + (direction === "back" ? -1 : 1);
    return slides[nextSlidePosition];
}

export type ScrollableStateOptions = {
    slideMinWidth?: ScrollableStateOptionsSlideMinWidth;
};

export type ScrollableStateOptionsSlideMinWidth = number | ((container: HTMLElement) => number);

export function useScrollableState(containerRef: RefObject<HTMLElement>, options: ScrollableStateOptions) {
    const [scrollingState, setScrollingState] = useState<ScrollingState>({
        isForwardAvailable: false,
        isBackAvailable: false,
        isAvailable: false,
    });

    const setScrollStateIfChanged = useCallback(
        (newState: ScrollingState) => {
            setScrollingState((currentState) => {
                if (isScrollingStateEqual(currentState, newState)) {
                    return currentState;
                }
                return newState;
            });
        },
        [setScrollingState]
    );

    useResizeObserver(containerRef, () => {
        if (containerRef.current) {
            setScrollStateIfChanged(computeScrollingState(containerRef.current));
        }
    });

    const container = containerRef.current;
    useEffect(() => {
        if (!container) {
            return;
        }
        const newScrollingState = computeScrollingState(container);
        setScrollingState(newScrollingState);

        const onScroll = () => {
            setScrollStateIfChanged(computeScrollingState(container));
        };
        container.addEventListener("scroll", onScroll, { passive: true });
        return () => {
            container.removeEventListener("scroll", onScroll);
        };
    }, [container, setScrollStateIfChanged]);

    return {
        isAvailable: scrollingState.isAvailable,
        isBackAvailable: scrollingState.isBackAvailable,
        isForwardAvailable: scrollingState.isForwardAvailable,
        back() {
            if (containerRef.current) {
                scrollInDirection(containerRef.current, "back", options);
            }
        },
        forward() {
            if (containerRef.current) {
                scrollInDirection(containerRef.current, "forward", options);
            }
        },
    };
}
