import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { IconButton, IconButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useControlledState } from "@react-stately/utils";

export type FavouriteButtonProps = {
    isFavourite?: boolean;
    defaultIsFavourite?: boolean;
    onChange?: (isFavourite: boolean) => void;
    color?: "light" | "primary";
    size?: "small" | "medium" | "large";
} & Omit<IconButtonProps, "size" | "color" | "onClick" | "onChange">;

const Wrapper = styled(IconButton)({
    position: "relative",
    '&[data-is-favourite="true"]': {
        "& .favourite-button-icon": {
            transform: "scale(1.05)",
        },
    },
    '&[data-is-favourite="false"]': {
        "& .favourite-button-icon": {
            transitionTimingFunction: "linear",
        },
    },
});

const FullIcon = styled(FavoriteIcon)({
    color: "red",
    position: "absolute",
    transform: "scale(0)",
    transitionProperty: "transform",
    transitionDuration: "0.2s",
    transitionTimingFunction: "cubic-bezier(0.010, 0.695, 0.85, 2.5)",
});

const EmptyIcon = styled(FavoriteBorderIcon)(({ theme }) => ({
    fill: theme.palette.primary.main,
    '&[data-color="light"]': {
        fill: "white",
    },
}));

export function FavouriteButton({
    isFavourite,
    defaultIsFavourite,
    onChange,
    color = "primary",
    size = "medium",
    ...props
}: FavouriteButtonProps) {
    const [finalIsFavourite, setIsFavourite] = useControlledState(isFavourite, defaultIsFavourite, (value) => {
        onChange?.(value!);
    });

    return (
        <Wrapper
            data-is-favourite={finalIsFavourite}
            onClick={(e) => {
                e.preventDefault();
                setIsFavourite(!isFavourite);
            }}
            size={size}
            {...props}
        >
            <EmptyIcon fontSize={size} data-color={color} />
            <FullIcon fontSize={size} className="favourite-button-icon" />
        </Wrapper>
    );
}
