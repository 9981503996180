import { styled } from "@mui/material/styles";
import { HTMLAttributes, ReactNode, useState } from "react";

import { ProductPriceFragment } from "../apiHooks/graphql";
import { useProductTranslation } from "../hooks/useProductTranslation";
import { PriceDataDialog } from "./Price";
import { Typography } from "./Typography";

type MarketPlaceMinPriceProps = {
    priceFormattedText: string;
    priceType?: "PER_PERSON" | "PER_GROUP";
    layout?: "horizontal" | "vertical";
    guidePricePricingData?: ProductPriceFragment["pricingData"];
} & HTMLAttributes<HTMLDivElement>;

export function MarketPlaceMinPrice({
    priceFormattedText,
    priceType,
    layout = "horizontal",
    guidePricePricingData,
    ...props
}: MarketPlaceMinPriceProps) {
    const [t] = useProductTranslation();

    return (
        <MarketPlaceMinPriceContainer pricingData={guidePricePricingData}>
            <MarketPlaceMinPriceWrapper variant="body" data-layout={layout} component="div" {...props}>
                <span>{t("price.from").toLowerCase()}</span>
                <span data-testid="product-price-v2">{priceFormattedText}</span>
                {priceType === "PER_GROUP" && <PriceTypeWrapper> {t("label.perGroup")}</PriceTypeWrapper>}
            </MarketPlaceMinPriceWrapper>
        </MarketPlaceMinPriceContainer>
    );
}

const MarketPlaceMinPriceWrapper = styled(Typography)(({ theme }) => ({
    '&[data-layout="vertical"]': {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(0.5),
    },
    '&[data-layout="horizontal"]': {
        "> span:not(:last-child)": {
            marginRight: theme.spacing(0.5),
        },
    },
}));

const PriceTypeWrapper = styled("span")({
    display: "inline-flex",
});

type MarketPlaceMinPriceContainerProps = {
    pricingData?: ProductPriceFragment["pricingData"];
    children?: ReactNode;
};

function MarketPlaceMinPriceContainer(props: MarketPlaceMinPriceContainerProps) {
    const { pricingData } = props;

    if (pricingData) {
        return <MarketPlaceMinPriceAdvanced pricingData={pricingData}>{props.children}</MarketPlaceMinPriceAdvanced>;
    }

    return props.children;
}

type MarketPlaceMinPriceAdvancedProps = {
    pricingData: ProductPriceFragment["pricingData"];
    children: ReactNode;
};
const MarketPlaceMinPriceAdvanced = ({ pricingData, children }: MarketPlaceMinPriceAdvancedProps) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDialogOpen(true);
    };

    const onClose = () => {
        setIsDialogOpen(false);
    };

    return (
        <>
            <Wrapper onClick={onClick}>{children}</Wrapper>
            <PriceDataDialog open={isDialogOpen} onClose={onClose} pricingData={pricingData} />
        </>
    );
};

const Wrapper = styled("div")(({ theme }) => ({
    cursor: "pointer",
    "&:hover": {
        backgroundColor: theme.palette.grey.A200,
        transition: theme.transitions.create("background-color", {
            duration: theme.transitions.duration.short,
        }),
        borderRadius: theme.shape.borderRadius,
    },
}));
