import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import React, { CSSProperties, ForwardedRef, forwardRef, HTMLAttributes } from "react";

import { HbmlElements } from "./HbmlElements";
import { hbmlNodeFactory } from "./common/hbmlNodeFactory";

export const HbmlDividerNodeComponent = styled(Divider)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "& .MuiDivider-wrapper": {
        padding: 0,
    },
}));

type ConfiguredStyles = "marginTop" | "marginBottom";

export const HbmlDividerNode = hbmlNodeFactory<
    typeof HbmlElements.DIVIDER,
    { style?: Pick<CSSProperties, ConfiguredStyles> },
    Omit<HTMLAttributes<HTMLDivElement>, "content">
>({
    type: HbmlElements.DIVIDER,
    Component: forwardRef(function ComponentInternal({ node, ...props }, ref: ForwardedRef<HTMLHRElement>) {
        const { style } = node;

        return <HbmlDividerNodeComponent {...props} ref={ref} style={style} />;
    }),
});
