import { ReactElement } from "react";

const formatters = new Map<string, PriceFormatter>();

function getOrCreateFormatterForLocale(languageCode: string, currency: string) {
    const key = `${languageCode}_${currency}`;
    let formatter = formatters.get(key);
    if (!formatter) {
        formatter = createFormatter(
            new Intl.NumberFormat(languageCode, {
                style: "currency",
                currency,
                minimumFractionDigits: 2,
                currencyDisplay: "code",
            })
        );
        formatters.set(key, formatter);
    }
    return formatter;
}

export const getPriceFormatter = (languageCode: string, currency: string) => {
    return getOrCreateFormatterForLocale(languageCode, currency);
};

function createFormatter(numberFormat: Intl.NumberFormat) {
    const formatter: PriceFormatter = (price: number) => {
        return numberFormat.format(price);
    };

    formatter.toElements = (price: number, typesToDisplay?: Intl.NumberFormatPartTypes[]) => {
        let parts = numberFormat.formatToParts(price);
        if (typesToDisplay) {
            parts = parts.filter(({ type }) => typesToDisplay.includes(type));
        }
        return parts.map(({ type, value }, index) => {
            const key = `${type}-${value}-${index}`;

            return (
                <span key={key} data-type={type}>
                    {value}
                </span>
            );
        });
    };
    return formatter;
}

export interface PriceFormatter {
    (price: number): string;

    toElements(price: number, typesToDisplay?: Intl.NumberFormatPartTypes[]): Array<ReactElement | string>;
}
