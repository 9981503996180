import { z } from "zod";

export const AlignAttributeSchema = z.union([
    z.literal("left"),
    z.literal("center"),
    z.literal("right"),
    z.literal("justify"),
    z.literal("start"),
    z.literal("end"),
    z.literal("match-parent"),
    z.literal("unset"),
]);
