import { ForwardedRef, forwardRef } from "react";

import type { HbmlElementNode } from "@holibob-packages/hbml";

import { HbmlRendererNodes } from "../HbmlRenderer";
import { TypographyDocument } from "../TypographyDocument";

export const HbmlBlockquoteNodeComponent = forwardRef(function HbmlBlockquoteNodeComponentInternal(
    { children, node: _node, ...props }: { node: HbmlElementNode; children: React.ReactNode },
    ref: ForwardedRef<HTMLQuoteElement>
) {
    return (
        <TypographyDocument variant="blockquote" ref={ref} {...props}>
            {children}
        </TypographyDocument>
    );
});

export function HbmlBlockquoteNode({ content }: { content: HbmlElementNode }) {
    return (
        <HbmlBlockquoteNodeComponent node={content}>
            <HbmlRendererNodes content={content.children} />
        </HbmlBlockquoteNodeComponent>
    );
}
