import { useNextTranslation } from "./useNextTranslation";

export type PromotionConfig = {
    label: string;
    color: string;
};

export type Banner = {
    text: string;
    backgroundColor?: string;
};

export type PromotionConfigInput = {
    banner?: Banner;
    promotionType?: string;
    isFeatured?: boolean;
};

export function useProductPromotionConfig({
    banner,
    promotionType,
    isFeatured,
}: PromotionConfigInput): PromotionConfig | undefined {
    const [t] = useNextTranslation("product");
    if (banner) {
        return { label: banner.text, color: banner.backgroundColor ?? "yellow" };
    } else if (promotionType === "SPONSORED") {
        return { label: t("banner.recommended.label"), color: "#E72526" };
    } else if (promotionType === "TEST") {
        return { label: t("banner.test.label"), color: "#FFD500" };
    } else if (promotionType === "BEST_SELLER") {
        return { label: t("banner.bestSeller.label"), color: "#FFD500" };
    } else if (isFeatured && promotionType) {
        return { label: promotionType, color: "#E72526" };
    }
    return undefined;
}
