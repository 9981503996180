import { z } from "zod";

export const BooleanAttributeSchema = z.preprocess((value) => {
    if (typeof value === "string") {
        if (value === "1" || value === "true") {
            return true;
        } else if (value === "0" || value === "false") {
            return false;
        }
    }
    return value;
}, z.boolean());
