import { styled } from "@mui/material/styles";

export const LabelList = styled("div")(({ theme }) => ({
    gap: theme.spacing(1),
}));

const LabelListItemContainer = styled("div")(({ theme }) => ({
    display: "inline",
    verticalAlign: "middle",
    alignItems: "center",
    "&:not(:last-child):after": {
        content: "'•'",
        padding: theme.spacing(0, 1),
        display: "inline",
        color: theme.palette.grey[400],
        verticalAlign: "middle",
    },
}));

type LabelListItemProps = {
    children: React.ReactNode;
};

export const LabelListItem = ({ children }: LabelListItemProps) => {
    return <LabelListItemContainer>{children}</LabelListItemContainer>;
};
