import { styled } from "@mui/material/styles";

import { CSSVariable } from "../utils/CSSVariable";

export const MainColumnVariableWidth = new CSSVariable("--main-column-width");
export const MainColumnVariableMargin = new CSSVariable("--main-column-margin");

export type MainColumnWrapperProps = {
    size?: "normal" | "wide";
} & React.HTMLAttributes<HTMLDivElement>;

export function MainColumnWrapper({ size = "normal", ...props }: MainColumnWrapperProps) {
    return <Wrapper data-ref="main-column-wrapper" data-size={size} {...props} data-testid="main-column-wrapper" />;
}

const Wrapper = styled("div")(({ theme }) => ({
    maxWidth: "100%",
    boxSizing: "border-box",
    marginLeft: MainColumnVariableWidth.reference,
    marginRight: MainColumnVariableWidth.reference,
    margin: theme.spacing(0, 2),
    [MainColumnVariableWidth.name]: `calc(100vw - ${MainColumnVariableWidth.reference} * 2)`,
    [MainColumnVariableMargin.name]: theme.spacing(2),
    "@media (min-width:1280px)": {
        maxWidth: MainColumnVariableWidth.reference,
        margin: "0 auto",

        [MainColumnVariableMargin.name]: `calc((100vw - ${MainColumnVariableWidth.reference})/2)`,
        '&[data-size="normal"]': {
            [MainColumnVariableWidth.name]: `1200px`,
        },
        '&[data-size="wide"]': {
            [MainColumnVariableWidth.name]: `1800px`,
        },
    },
}));
