import { styled } from "@mui/material/styles";
import { ReactNode } from "react";

import { useProductTranslation } from "../hooks/useProductTranslation";
import { Price, PriceProps, PriceSize } from "./Price";

export type MinPriceProps = {
    extraLabel?: ReactNode;
    size?: PriceSize;
} & PriceProps;

const Wrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
}));

const Label = styled("div")(({ theme }) => ({
    textAlign: "right",
    fontSize: 12,
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
}));

const PriceStyled = styled(Price)({
    alignSelf: "end",
});

const ExtraLabel = styled("div")(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontFamily: theme.typography.fontFamily,
    fontSize: 12,
    textAlign: "right",
}));

export function MinPrice({
    amount,
    size = "normal",
    onPricingDataDialogOpenChange,
    isPricingDataDialogOpen,
    pricingData,
    parts,
    language,
    defaultIsPricingDataDialogOpen,
    currency,
    extraLabel,
    ...props
}: MinPriceProps) {
    const [t] = useProductTranslation();
    return (
        <Wrapper {...props}>
            <Label>{t("price.from", { currency })}</Label>
            <PriceStyled
                size={size}
                amount={amount}
                language={language}
                parts={parts}
                currency={currency}
                onPricingDataDialogOpenChange={onPricingDataDialogOpenChange}
                isPricingDataDialogOpen={isPricingDataDialogOpen}
                pricingData={pricingData}
                defaultIsPricingDataDialogOpen={defaultIsPricingDataDialogOpen}
            />
            {extraLabel && <ExtraLabel>{extraLabel}</ExtraLabel>}
        </Wrapper>
    );
}
