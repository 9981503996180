import type { HbmlElementNode } from "@holibob-packages/hbml";

import { HbmlRendererNodes } from "../HbmlRenderer";
import { TypographyDocument } from "../TypographyDocument";

export function HbmlBulletedListNode({ content }: { content: HbmlElementNode }) {
    return (
        <TypographyDocument variant="ul">
            <HbmlRendererNodes content={content.children} />
        </TypographyDocument>
    );
}
