import { HbmlElementNode } from "./types";

export const ElementTypes = {
    PARAGRAPH: "paragraph",
    H1: "heading-one",
    H2: "heading-two",
    H3: "heading-three",
    DIVIDER: "divider",
    MEDIA_OBJECT: "media-object",
    MEDIA_OBJECT_MEDIA: "media-object-media",
    MEDIA_OBJECT_CONTENT: "media-object-content",
    RAW_TEXT: "raw-text",
    VAULT_IMAGE: "vaultImage",
    HERO: "hero",
    CURATIONS_V2: "curations-v2",
    CURATION_PRODUCTS: "curation-products",
    CURATION_PRODUCTS_V2: "curation-products-v2",
    POWERED_BY_HOLIBOB: "powered-by-holibob",
    UPDATE_COOKIE_CONSENT: "update-cookie-consent",
    CONSUMER_TRIP: "consumer-trip",
    HERO_SUBTITLE: "hero-subtitle",
    HERO_TITLE: "hero-title",
    HERO_DESCRIPTION: "hero-description",
    TABLE: "table",
    TABLE_ROW: "table-row",
    TABLE_CELL: "table-cell",
    SOCIAL_LINKS: "social-links",
    HEADER_NAVIGATION: "header-navigation",
    NUMBERED_LIST: "numbered-list",
    BULLETED_LIST: "bulleted-list",
    LIST_ITEM: "list-item",
    LIST_ITEM_CONTENT: "lic",
    BLOCKQUOTE: "block-quote",
    SECTION: "section",
    STACK: "stack",
    STACK_ITEM: "stack-item",
    SECTION_HEADER: "section-header",
    ELFSIGHT_EMBED: "elfsight-embed",
    LINK: "link",
    BUTTON: "button",
    FAQ: "faq",
    FAQ_ITEM: "faq-item",
    FAQ_ITEM_TITLE: "faq-item-title",
    FAQ_ITEM_CONTENT: "faq-item-content",
    ICON: "icon",
    HTML: "html",
} as const;

export type ElementType = HbmlElementNode["type"];
