import { PageType } from "containers/Page/Page";
import { useConsumerTripEventCreateMutation } from "gql";
import { useConsumerTripInfo } from "hooks/useConsumerTripInfo";
import { useCallback, useEffect, useState } from "react";

import { ConsumerTripEventCreateInput } from "@holibob-packages/consumer-trip-event";

import { useDefaultConfig, useGA4MeasurementId } from "./googleAnalyticsHooks";

type Product = {
    id: string;
    name: string | null;
    price?: number;
    currency?: string;
};

const canUseGTag = (): boolean => typeof window !== "undefined" && !!window.gtag;

export function usePageViewGA4() {
    const measurementId = useGA4MeasurementId();
    const defaultConfig = useDefaultConfig();

    return useCallback(
        (pageType: PageType, title: string, itemId?: string) => {
            if (!measurementId) return;

            if (canUseGTag()) {
                window.gtag("config", measurementId, {
                    content_type: pageType,
                    content_id: itemId,
                    page_title: title,
                    ...defaultConfig,
                });
                window.gtag("event", "page_view");
            }
        },
        [defaultConfig, measurementId]
    );
}

export function usePageViewConsumerTripEvent() {
    const { consumerTripId } = useConsumerTripInfo();
    const [consumerTripEventCreate] = useConsumerTripEventCreateMutation();
    return useCallback(
        (pageType: PageType, itemId?: string) => {
            const event: ConsumerTripEventCreateInput = {
                consumerTripId,
                type: "WL_PAGE_VIEW",
                meta: { pageType, itemId },
            };

            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            consumerTripEventCreate({ variables: event });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}

export function useProductCheckAvailabilityEvent() {
    const { consumerTripId } = useConsumerTripInfo();
    const [consumerTripEventCreate] = useConsumerTripEventCreateMutation();
    return useCallback(
        (itemId?: string) => {
            const event: ConsumerTripEventCreateInput = {
                consumerTripId,
                type: "WL_CHECK_AVAILABILITY_CLICK",
                meta: { itemId },
            };

            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            consumerTripEventCreate({ variables: event });
        },
        [consumerTripEventCreate, consumerTripId]
    );
}

export function usePageView() {
    const pageViewGA4 = usePageViewGA4();
    const pageViewConsumerTripEvent = usePageViewConsumerTripEvent();

    return useCallback(
        (pageType: PageType, title: string, itemId?: string | undefined) => {
            pageViewGA4(pageType, title, itemId);
            pageViewConsumerTripEvent(pageType, itemId);
        },
        [pageViewConsumerTripEvent, pageViewGA4]
    );
}

export function useRegisterPageView(pageType: PageType, title?: string | null, itemId?: string | undefined) {
    const [prevTitle, setPrevTitle] = useState<string | null>(null);
    const pageView = usePageView();

    if (title && prevTitle !== title) {
        setPrevTitle(title);
        pageView(pageType, title, itemId);
    }
}

export function viewItem(productId: string, productName: string, price: number, currency: string) {
    if (canUseGTag())
        window.gtag("event", "view_item", {
            currency,
            value: price,
            items: [{ item_id: productId, item_name: productName }],
        });
}

export function useViewItem(
    productId?: string,
    productName?: string | null,
    price?: number | null,
    currency?: string | null
) {
    useEffect(() => {
        if (price != null && currency && productId && productName) {
            viewItem(productId, productName, price, currency);
        }
    }, [productId, productName, price, currency]);
}

export function viewItemList(entityId: string, entityType: string, products: Product[]) {
    if (canUseGTag()) {
        window.gtag("event", "view_item_list", {
            item_list_id: entityId,
            item_list_name: entityType,
            items: products.map(({ id, name }) => ({ item_name: name, item_id: id })),
        });
    }
}

export function useViewItemList(entityId?: string, entityType?: string, products?: Product[]) {
    useEffect(() => {
        if (entityId && entityType && products?.length) {
            viewItemList(entityId, entityType, products);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityId, entityType, JSON.stringify(products)]);
}

export function addToCart(
    productId: string,
    productName: string | null,
    price: number | null,
    currency: string | null
) {
    if (canUseGTag())
        window.gtag("event", "add_to_cart", {
            currency,
            value: price,
            items: [{ item_id: productId, item_name: productName }],
        });
}

export function viewCart(products: Product[], price: number, currency: string) {
    if (canUseGTag()) {
        window.gtag("event", "view_cart", {
            currency,
            value: price,
            items: products.map(({ id, name, currency, price }) => ({
                item_name: name,
                item_id: id,
                currency,
                price,
            })),
        });
    }
}

export function useViewCart(products?: Product[], price?: number, currency?: string) {
    useEffect(() => {
        if (price != null && currency && products?.length) {
            viewCart(products, price, currency);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(products), price, currency]);
}

export function purchase(products: Product[], price: number, currency: string) {
    if (canUseGTag()) {
        window.gtag("event", "purchase", {
            currency,
            value: price,
            items: products.map(({ id, name, currency, price }) => ({
                item_name: name,
                item_id: id,
                currency,
                price,
            })),
        });
    }
}
